// import LocomotiveScroll from 'locomotive-scroll'
import UniversalTilt from 'universal-tilt.js'
import Rellax from 'rellax'

window.addEventListener('DOMContentLoaded', function () {
  function doStuff (callback) {
    callback()
  }

  doStuff(function () {
    document.body.className = 'visible'
  })
}, false)

const rellax = new Rellax('.rellax')

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
const vh = window.innerHeight * 0.01 // Define viewport height

function setViewportHeight () { // Function for setting viewport height
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('resize', setViewportHeight)

const links = document.querySelectorAll('.link')
const sections = document.querySelectorAll('section')

function changeLinkState () {
  let index = sections.length

  while (--index && window.scrollY + 50 < sections[index].offsetTop) {}

  links.forEach((link) => link.classList.remove('active'))
  links[index].classList.add('active')
}

changeLinkState()
window.addEventListener('scroll', changeLinkState)

setViewportHeight() // Set the height

function elementInViewport (el) {
  let top = el.offsetTop
  let left = el.offsetLeft
  const width = el.offsetWidth
  const height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    left += el.offsetLeft
  }

  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth) &&
    (top + height) > window.pageYOffset &&
    (left + width) > window.pageXOffset
  )
}

const manifestoParagraph = document.querySelectorAll('.manifesto-paragraph p')
const manifestoImages = document.querySelectorAll('.manifesto-image')
const manifestoImageContainer = document.querySelector('.manifesto-content-image-container')
const venDiagramContainer = document.querySelector('.manifesto-ven-diagram')
const manifestoSummary = document.querySelectorAll('.texts p')

document.addEventListener('scroll', () => {
  let i
  for (i = 0; i < manifestoParagraph.length; i++) {
    if (elementInViewport(manifestoParagraph[i])) {
      manifestoImages[i].classList.add('in-view')
      manifestoParagraph[i].classList.add('in-view')
    } else {
      manifestoImages[i].classList.remove('in-view')
      manifestoParagraph[i].classList.remove('in-view')
    }
  }
  const manifesto = document.querySelector('#manifesto')
  const body = document.querySelector('body')
  const scroll = window.scrollY

  if (scroll >= manifesto.offsetTop) {
    body.classList.add('visible')
  } else {
    body.classList.remove('visible')
  }

  if (scroll > venDiagramContainer.getBoundingClientRect().top + document.documentElement.scrollTop - window.innerHeight) {
    manifestoImageContainer.classList.add('bottom')
  } else {
    manifestoImageContainer.classList.remove('bottom')
  }

  let x
  for (x = 0; x < manifestoSummary.length; x++) {
    if (elementInViewport(manifestoSummary[x])) {
      manifestoSummary[x].classList.add('in-view')
    } else {
      manifestoSummary[x].classList.remove('in-view')
    }
  }
})

let i
for (i = 0; i < manifestoParagraph.length; i++) {
  if (elementInViewport(manifestoParagraph[i])) {
    manifestoImages[i].classList.add('in-view')
    manifestoParagraph[i].classList.add('in-view')
  } else {
    manifestoImages[i].classList.remove('in-view')
    manifestoParagraph[i].classList.remove('in-view')
  }
}

manifestoImages[0].classList.add('in-view')

function calculateProgress (element) {
  const scroll = window.pageYOffset
  const top = element.getBoundingClientRect().top + document.documentElement.scrollTop
  const height = window.innerHeight
  const elementHeight = element.offsetHeight
  const topScroll = top + scroll - (height / 2)
  let start = scroll + (top * -1)
  start = clamp(start, 0, height)
  const progress = start / (elementHeight - height)

  return progress
}

window.onscroll = function () {
  const lerp = (x, y, a) => x * (1 - a) + y * a
  const progress = calculateProgress(venDiagramContainer)
  let startValue = 2
  let endValue = 60
  let offset = lerp(0, 39, progress)
  let v = lerp(startValue, endValue, progress)
  const leftCircle = document.querySelector('#left-circle')
  const rightCircle = document.querySelector('#right-circle')
  const venDiagram = document.querySelector('.manifesto-ven-diagram-container')

  const x = window.matchMedia('(min-width: 920px)')

  if (x.matches) {
    startValue = 8
    endValue = 60
    v = lerp(startValue, endValue, progress)
    offset = lerp(0, 30, progress)
    leftCircle.style.transform = `translateX(calc(${clamp(v, startValue, 50)}vw - ${clamp(offset, 0, 15)}vw))`
    rightCircle.style.transform = `translateX(calc(-${clamp(v, startValue, 50)}vw + ${clamp(offset, 0, 15)}vw))`
    if (v >= 50) {
      venDiagram.classList.add('blue')
    } else {
      venDiagram.classList.remove('blue')
    }
  } else {
    startValue = 8
    endValue = 60
    v = lerp(startValue, endValue, progress)
    offset = lerp(0, 30, progress)
    leftCircle.style.transform = `translateY(calc(${clamp(v, startValue, 50)}vh - ${clamp(offset, 0, 15)}vh))`
    rightCircle.style.transform = `translateY(calc(-${clamp(v, startValue, 50)}vh + ${clamp(offset, 0, 15)}vh))`
    if (v >= 50) {
      venDiagram.classList.add('blue')
    } else {
      venDiagram.classList.remove('blue')
    }
  }
}

function getBlockHeight () {
  const services = document.querySelectorAll('.service')
  const firstBlock = services[0].querySelector('.blocks')
  const secondBlock = services[1].querySelector('.blocks')
  const thirdBlock = services[2].querySelector('.blocks')
  const tallest = Math.max(
    firstBlock.offsetHeight,
    secondBlock.offsetHeight,
    thirdBlock.offsetHeight)
  if (tallest !== firstBlock.offsetHeight) {
    firstBlock.style.minHeight = `${tallest}px`
  } else {
    firstBlock.style.minHeight = 'auto'
  }
  if (tallest !== secondBlock.offsetHeight) {
    secondBlock.style.minHeight = `${tallest}px`
  } else {
    secondBlock.style.minHeight = 'auto'
  }
  if (tallest !== thirdBlock.offsetHeight) {
    thirdBlock.style.minHeight = `${tallest}px`
  } else {
    thirdBlock.style.minHeight = 'auto'
  }
}

function indexOfMax (arr) {
  if (arr.length === 0) {
    return -1
  }

  let max = arr[0]
  let maxIndex = 0

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i
      max = arr[i]
    }
  }

  return maxIndex
}

function equalHeights () {
  const findClass = document.querySelectorAll('.service .blocks')
  let tallest = 0
  const heights = []
  // Loop over matching divs
  for (i = 0; i < findClass.length; i++) {
    const ele = findClass[i]
    let eleHeight = ele.offsetHeight
    tallest = (eleHeight > tallest ? eleHeight : tallest) /* look up ternary operator if you dont know what this is */
    heights.push(eleHeight++)
  }

  const tallestIndex = indexOfMax(heights)

  console.log(heights, findClass.length)

  for (i = 0; i < findClass.length; i++) {
    if (i === tallestIndex) {
      continue
    } else {
      findClass[i].style.minHeight = tallest + 'px'
    }
  }
}
window.addEventListener('load', equalHeights)
window.addEventListener('resize', equalHeights)
